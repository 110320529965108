import * as Lucide from "lucide-react";
import type { ActionFunction } from "react-router";
import { useFetcher } from "react-router";
import { DropdownMenuItem } from "~/components/ui/dropdown-menu";
import { signOut } from "./token.server";

export const action: ActionFunction = async ({ request }) => {
  return signOut(request);
};

export function SignOutItem() {
  const fetcher = useFetcher();

  return (
    <DropdownMenuItem onSelect={() => fetcher.submit({}, { method: "post", action: "/signout" })}>
      <Lucide.LogOut />
      <span>Sign out</span>
    </DropdownMenuItem>
  );
}
