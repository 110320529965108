import * as Lucide from "lucide-react";
import { Link } from "react-router";
import { Button } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { useFeedUrl, useUser } from "~/with-user";
import { SignOutItem } from "./signout";

function AddToApplePodcastsItem() {
  const feedUrl = useFeedUrl();
  const podcastUrl = feedUrl.replace(/^https?:/, "podcast:");

  return (
    <DropdownMenuItem asChild>
      <Link to={podcastUrl}>
        <Lucide.Apple />
        Add to Apple Podcasts
      </Link>
    </DropdownMenuItem>
  );
}

export function UserMenu() {
  const user = useUser();

  return (
    user && (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline2" className="rounded-full size-5  ml-1 text-muted-foreground p-0">
            <Lucide.ChevronDown />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <AddToApplePodcastsItem />
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link to="/settings/account">
              <Lucide.Settings />
              Account settings
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <SignOutItem />
        </DropdownMenuContent>
      </DropdownMenu>
    )
  );
}
