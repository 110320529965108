import { Link, type MetaFunction, Outlet } from "react-router";
import { UserMenu } from "./user/menu";
import { useUser } from "./with-user";

// HEADER

export function Logo({ className }: { className?: string }) {
  return (
    <span className={`flex items-center ${className}`}>
      <img src="/favicon.svg" alt="logo" className="size-5 mr-2" />
      <span className="font-semibold">Sumcast</span>
    </span>
  );
}

function Header() {
  const user = useUser();
  return (
    <div className="w-full">
      <div className="flex flex-row items-center content-w pt-8 pb-4">
        <Link to="/" className="flex flex-row items-center px-1 rounded-md text-gray-900 hover:bg-white">
          <Logo />
          {user && <span>&nbsp;for {user.firstName}</span>}
        </Link>
        <UserMenu />
        <div className="flex-grow" />
      </div>
    </div>
  );
}

export default function MainLayout() {
  return (
    <div className="flex flex-col h-full">
      <Header />
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  );
}

export const meta: MetaFunction = () => {
  return [{ title: "Sumcast" }, { name: "description", content: "Summarize Slack conversations as a daily podcast." }];
};
